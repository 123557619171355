import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import Team from '../components/About/team';


export default function Contact_Us() {
    return (
        <>
        <Navbar />
        <Team />
        <Footer />
        </>
    )
}