import Navbar from '../components/Navbar'
import Footer from '../components/Footer';


export default function NoPage() {
    return (
        <>
        <Navbar />
        <div className="App">
            <p>Wronge Page</p>
        </div>
        <Footer />
        </>
    )
}